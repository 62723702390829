<template>
  <div>
    <orders
      :patient="patient"
      :walkin="1"
      @encounterUpdated="contextUpdateEncounter"
    /></div>
</template>
<script>
import Orders from '../../clinical/working-area/components/patient-chart/components/orders/Orders.vue'
import APP_CONTEXT_MIXIN from '../../mixins/app-context.mixin'

export default {
  name: 'WalkIn',
  components: { Orders },
  mixins: [APP_CONTEXT_MIXIN],
  props: {
    patient: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
