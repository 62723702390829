<template>
  <b-container class="viewer">
    <b-row
      class="w-100"
      align-h="end"
    >

      <b-button
        variant="primary"
        size="sm"
        @click="visible=true"
      >
        <span style="margin-left: 4px;"> Walk in Orders</span>
      </b-button>
    </b-row>

    <b-row class="w-100">
      <doctype-render
        class="px-4 py-2"
        style="width: 100%"
        :fields="[]"
        :refresh="refresh"
        :doctype-input="doctype"
        :doc_ref="doctypeReference"
      />
    </b-row>
    <b-modal
      id="walk-in"
      v-model="visible"
      title="Add a walk in order"
      :no-enforce-focus="true"
      size="lg"
      :hide-footer="true"
      @ok="submitEncounterWithWalkIn"
    >
      <walk-in :patient="doctypeReference" />
      <span
        v-if="errorMessage"
        style="color: red;"
      >{{ errorMessage }}</span>
      <b-button
        variant="outline-secondary"
        size="sm"
        style="width: 100%"
        @click="uploadFile"
      >
        <i
          class="fa fa-paperclip"
          aria-hidden="true"
        />Add Attachments as evidence for this Order
      </b-button>
      <b-button
        :disabled="loading"
        style="float: right; margin-top: 16px"
        variant="primary"
        @click="submitEncounterWithWalkIn"
      >
        Submit
      </b-button>
      <ul>
        <li
          v-for="(file, index) of uploadedFiles"
          :key="index"
        >
          {{ file }}
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>

<script>
import DoctypeRender from '@/views/components/formbuilder/html/doctype/DoctypeRender.vue'
import WalkIn from './WalkIn.vue'

export default {
  name: 'Viewer',
  components: { DoctypeRender, WalkIn },
  props: {
    selectedDoctype: { type: String, default: null, required: false },
  },
  data() {
    return {
      doctype: 'Patient',
      visible: false,
    }
  },
  computed: {
    doctypeReference() {
      return this.$route.params.id || this.selectedDoctypeReference
    },
  },
  created() { },
  methods: {
    buttonAction(action) {
      this.$emit('button-action', action)
    },
  },
}
</script>

<style scoped>
.viewer {
    overflow-y: scroll;

    /* height: calc(100vh - 80px); */
}

.render {}
</style>
